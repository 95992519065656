import React from "react";
import _ from "lodash";
import { notify } from "react-notify-toast";

import { NetworkAPI } from "apis";
import NetworksSelector from "./NetworksSelector";
import LoadingUI from "components/common/LoadingUI";
// import YieldSetsSelector from "./YieldSetsSelector";
// import Checkbox from "components/common/Checkbox";

const UNIT_MODE = {
  INITIAL: "INITIAL",
  NORMAL: "NORMAL",
  WATCH_LIST: "WATCH_LIST",
  SUSPENDED: "SUSPENDED",
};

const UNIT_STATUS = {
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
  // PROCESSING: "PROCESSING",
  // ARCHIVED: "ARCHIVED",
};

const SELECT_UNIT_TYPE = {
  BY_MODE: "BY_MODE",
  BY_GAM_UNIT_ID: "BY_GAM_UNIT_ID",
  BY_EXT_UNIT_ID: "BY_EXT_UNIT_ID",
};

const buttonClass =
  "px-2 py-1 bg-gray-100 text-xs rounded border border-gray-400 hover:bg-gray-200 text-gray-900 font-semibold";

class BatchUpdateAdUnits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNetwork: null,

      selectUnitType: SELECT_UNIT_TYPE.BY_MODE,
      selectAdUnitsString: "",
      unitIds: [],

      selectedUnits: null,

      unitStatus: null,

      resultUnits: null,

      errMsg: "",
      isSaving: false,
    };

    this.onSelectNetwork = this.onSelectNetwork.bind(this);
    this.onSelectUnitTypeChanged = this.onSelectUnitTypeChanged.bind(this);
    this.onUnitStatusChanged = this.onUnitStatusChanged.bind(this);
    this.getUnitsByMode = this.getUnitsByMode.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validateUnitsByUnitIds = this.validateUnitsByUnitIds.bind(this);
    this.handleUpdateUnitsByStatus = this.handleUpdateUnitsByStatus.bind(this);
  }

  componentDidMount() {
    document.title = `Batch Update Ad Units | YB Observer`;
  }

  onSelectNetwork(network) {
    this.setState({
      selectedNetwork: network,
      selectedYieldSet: null,
    });
  }

  onSelectUnitTypeChanged(newType) {
    const { selectUnitType } = this.state;
    this.setState({ selectUnitType: newType, selectedUnits: null });
  }

  onUnitStatusChanged(newStatus) {
    this.setState({ unitStatus: newStatus });
  }

  onChange(e) {
    this.setState({
      selectAdUnitsString: e.target.value,
      unitIds: this._transformCsvToArray(e.target.value),
    });
  }

  _transformCsvToArray(csv) {
    let string = this._transformFn(csv);
    let items = _.split(string, ",");
    items = _.uniq(_.compact(items));
    return items;
  }

  async getUnitsByMode() {
    try {
      const { selectedNetwork } = this.state;
      console.log(selectedNetwork);
      const networkId = selectedNetwork.networkId;
      const mode = UNIT_MODE.INITIAL;
      const { units } = await NetworkAPI.getUnitsByMode({ networkId, mode });
      console.log(units);

      this.setState({ selectedUnits: units });
    } catch (err) {
      console.log(err);
    }
  }

  async validateUnitsByUnitIds() {
    try {
      const { selectedNetwork, unitIds } = this.state;
      const networkId = selectedNetwork.networkId;
      const { units } = await NetworkAPI.getUnitsByUnitIds({
        networkId,
        unitIds,
      });
      console.log(units);

      this.setState({ selectedUnits: units });
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: typeof err === "object" ? err.toString() : err,
      });
    }
  }

  async handleUpdateUnitsByStatus() {
    this.setState({ isSaving: true });
    try {
      const { selectedNetwork, selectedUnits, unitStatus } = this.state;
      const networkId = selectedNetwork.networkId;
      const status = unitStatus;
      const unitIds = _.map(selectedUnits, "adUnitId");
      const params = {
        unitIds,
        status,
      };
      const result = await NetworkAPI.updateUnitsByStatus({
        networkId,
        params,
      });
      console.log(result);

      const { units } = await NetworkAPI.getUnitsByUnitIds({
        networkId,
        unitIds,
      });
      console.log(units);
      this.setState({ resultUnits: units, errMsg: null, isSaving: false });
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: typeof err === "object" ? err.toString() : err,
        resultUnits: null,
        isSaving: false,
      });
    }
  }

  _transformFn(content) {
    const charArr = content.split("");
    let validContent = [];
    for (let c of charArr) {
      const lastC = _.last(validContent);
      switch (c) {
        case "\r": {
          continue;
        }

        case "\n":
        case " ": {
          // Ignore if we see continuous seperator
          if (lastC === undefined || lastC === ",") {
            continue;
          }

          c = ",";
          validContent.push(c);
          break;
        }

        default: {
          if (c >= "0" && c <= "9") {
            validContent.push(c);
          } else if (lastC && lastC != ",") {
            validContent.push(",");
          }
          break;
        }
      }
    }

    return validContent.join("");
  }

  render() {
    const {
      selectedNetwork,
      selectUnitType,
      selectedUnits,
      selectAdUnitsString,
      unitIds,
      unitStatus,
      isSaving,
      errMsg,
      resultUnits,
    } = this.state;

    const isUpdateAllowed =
      !isSaving && selectedUnits && selectedUnits.length > 0 && unitStatus;

    return (
      <div>
        <div className="bg-white px-12">
          <h1 className="pt-8 pb-4 text-4xl font-extrabold text-gray-900">
            Batch Update Ad Units
          </h1>
        </div>

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          <div className="flex">
            <div className="w-1/2">
              <div className="mb-4">
                1. Select Network:
                <div>
                  <NetworksSelector
                    selectedNetwork={selectedNetwork}
                    handleOnChange={this.onSelectNetwork}
                  ></NetworksSelector>
                </div>
              </div>

              {selectedNetwork && (
                <div className="mb-4">
                  2. Select Units:
                  <div className="flex items-center gap-4">
                    <label
                      htmlFor={`select_unit_by_mode`}
                      className="cursor-pointer"
                    >
                      <input
                        id={`select_unit_by_mode`}
                        type="radio"
                        name={`select_units_radio`}
                        value={"BY_MODE"}
                        checked={selectUnitType === "BY_MODE"}
                        onChange={() => this.onSelectUnitTypeChanged("BY_MODE")}
                      />{" "}
                      By Unit Mode
                    </label>

                    <label
                      htmlFor={`select_unit_freeform`}
                      className="cursor-pointer"
                    >
                      <input
                        id={`select_unit_freeform`}
                        type="radio"
                        name={`select_units_radio`}
                        value={"FREEFORM"}
                        checked={selectUnitType === "FREEFORM"}
                        onChange={() =>
                          this.onSelectUnitTypeChanged("FREEFORM")
                        }
                      />{" "}
                      Manual Input
                    </label>
                  </div>
                  <div>
                    {selectUnitType === "BY_MODE" && (
                      <button
                        className={buttonClass}
                        type="button"
                        onClick={this.getUnitsByMode}
                      >
                        Get INITIAL mode units
                      </button>
                    )}
                    {selectUnitType === "FREEFORM" && (
                      <div className="mb-4">
                        <div className="text-sm text-gray-600">
                          (Use <b>comma</b>, <b>whitespace</b> or{" "}
                          <b>line break</b> to separate ids){" "}
                          <div className="text-sm text-gray-600">
                            ex. 423,235,246
                          </div>
                        </div>

                        <div>
                          <textarea
                            className="border rounded w-full border-gray-400 p-2"
                            value={selectAdUnitsString}
                            onChange={this.onChange}
                            rows={5}
                          ></textarea>
                          <div className="text-sm leading-none text-gray-700">
                            {unitIds.length} units
                          </div>
                          <button
                            className={buttonClass}
                            type="button"
                            onClick={this.validateUnitsByUnitIds}
                          >
                            Validate units
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="mb-4">
                {selectedUnits && (
                  <div
                    className={`border bg-white ${
                      selectedUnits && "shadow border-gray-400"
                    } rounded mt-4 p-4`}
                  >
                    <div className="mb-2 font-semibold text-gray-700">
                      Ad Units: ({selectedUnits.length} units)
                    </div>
                    <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                      <ul>
                        {selectedUnits.map((unit) => {
                          return (
                            <li key={unit.adUnitId} className="text-gray-900">
                              {unit.adUnitId} - {unit.name} ({unit.status})
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>

              {selectedUnits && selectedUnits.length > 0 && (
                <div>
                  3. Update Unit Status to:
                  <div className="mb-4">
                    <div className="flex items-center gap-4">
                      <label
                        htmlFor={`unit_status_running`}
                        className="cursor-pointer"
                      >
                        <input
                          id={`unit_status_running`}
                          type="radio"
                          name={`unit_status_radio`}
                          value={UNIT_STATUS.RUNNING}
                          checked={unitStatus === UNIT_STATUS.RUNNING}
                          onChange={() =>
                            this.onUnitStatusChanged(UNIT_STATUS.RUNNING)
                          }
                        />{" "}
                        {UNIT_STATUS.RUNNING}
                      </label>

                      <label
                        htmlFor={`unit_status_paused`}
                        className="cursor-pointer"
                      >
                        <input
                          id={`unit_status_paused`}
                          type="radio"
                          name={`unit_status_radio`}
                          value={UNIT_STATUS.PAUSED}
                          checked={unitStatus === UNIT_STATUS.PAUSED}
                          onChange={() =>
                            this.onUnitStatusChanged(UNIT_STATUS.PAUSED)
                          }
                        />{" "}
                        {UNIT_STATUS.PAUSED}
                      </label>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className={`${
                        isUpdateAllowed
                          ? "bg-blue-500 text-white"
                          : "bg-gray-400 text-gray-800"
                      } rounded px-6 py-2 font-semibold`}
                      onClick={this.handleUpdateUnitsByStatus}
                      disabled={!isUpdateAllowed}
                    >
                      Update status
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="ml-4 w-1/2">
              {isSaving && <LoadingUI></LoadingUI>}

              {errMsg && (
                <div className="rounded mb-4 bg-red-100 p-4 font-semibold text-red-700">
                  {errMsg}
                </div>
              )}

              <div>
                {resultUnits && (
                  <div className="mb-4">
                    <div className="text-lg font-semibold">Update Results:</div>
                    <div
                      className={`border bg-white ${
                        resultUnits && "shadow border-green-400"
                      } rounded p-4`}
                    >
                      <div className="mb-2 font-semibold text-gray-700">
                        Ad Units: ({resultUnits.length} units)
                      </div>
                      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                        <ul>
                          {resultUnits.map((unit) => {
                            return (
                              <li key={unit.adUnitId} className="text-gray-900">
                                {unit.adUnitId} - {unit.name} ({unit.status})
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BatchUpdateAdUnits;
