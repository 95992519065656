import React from "react";
import _ from "lodash";
import { NetworkAPI, UnitAPI } from "apis";
import NetworkPageWrapper from "components/diagnoser/NetworkPageWrapper";
import YieldSetsSelector from "components/ops-mgmt/account/YieldSetsSelector";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";
import UnitStatus from "components/common/UnitStatus";
import LoadingUI from "components/common/LoadingUI";
import UnitMode from "components/common/UnitMode";
import ItemsFilter from "components/ops-mgmt/common/ItemsFilter";

const UNIT_STATUS = {
  RUNNING: 0,
  PAUSED: 1,
};

const UNIT_MODE = {
  INITIAL: "INITIAL",
  NORMAL: "NORMAL",
  WATCH_LIST: "WATCH_LIST",
  SUSPENDED: "SUSPENDED",
};

const SEARCH_KEYS = {
  UNIT_ID: "adUnitId",
  EXT_UNIT_ID: "extGamAdUnitId",
  NAME: "name",
  PARENT_PATH: "parentPath",
  SITE_NETWORK_CODE: "siteNetworkCode",
  TAGS: "tags",
};

class UnitsSearchViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      networkId: null,
      networkInfo: null,
      selectedYieldSet: null,

      filter: {
        ys: "ALL",
        mode: "ALL",
        status: "ALL",
      },

      units: null,
    };

    this.unitsTableRef = React.createRef();

    this._getQueryParams = this._getQueryParams.bind(this);
    this.searchUnits = this.searchUnits.bind(this);
    this.handleYsChanged = this.handleYsChanged.bind(this);
    this.handleModeChanged = this.handleModeChanged.bind(this);
    this.handleStatusChanged = this.handleStatusChanged.bind(this);
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "match.params.networkId");
    this.setState({ networkId, isLoading: true });

    if (networkId) {
      document.title = `Search Units of ${networkId} | YB Observer`;

      try {
        const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
        this.setState({ networkInfo, errMsg: null });
      } catch (err) {
        this.setState({
          errMsg: err,
          networkInfo: null,
        });
      }
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Route changed!
      // console.log(
      //   "route changed",
      //   prevProps.location.pathname,
      //   this.props.location.pathname
      // );
    }
  }

  handleModeChanged(newMode) {
    let filter = { ...this.state.filter };
    filter.mode = newMode;

    this.setState({ filter });
  }

  handleStatusChanged(newStatus) {
    let filter = { ...this.state.filter };
    filter.status = newStatus;

    this.setState({ filter });
  }

  handleYsChanged(newYs) {
    let filter = { ...this.state.filter };
    filter.ys = newYs;

    this.setState({ filter, selectedYieldSet: newYs === "ALL" ? null : newYs });
  }

  _getQueryParams() {
    const { filter } = this.state;

    let queryParams = {};
    if (filter.ys !== "ALL") {
      queryParams.yieldSetId = filter.ys.yieldSetId;
    }

    if (filter.mode !== "ALL") {
      queryParams.mode = filter.mode;
    }

    if (filter.status !== "ALL") {
      queryParams.status = filter.status;
    }

    return queryParams;
  }

  async searchUnits() {
    this.setState({ isLoading: true });
    try {
      const networkId = this.state.networkId;
      const queryParams = this._getQueryParams();
      // new api
      const r = await UnitAPI.searchUnits({ networkId, ...queryParams });
      this.setState({ units: r.units });

      this.unitsTableRef.current.handleClearAll();
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
      });
    }
    this.setState({ isLoading: false });
  }

  render() {
    const {
      isLoading,
      errMsg,
      networkId,
      networkInfo,

      filter,
      selectedYieldSet,
      isFilterDisabled = false,
      units,
    } = this.state;

    return (
      <>
        <BaseHeader title="Units Search"></BaseHeader>
        <NetworkPageWrapper
          // isLoading={isLoading}
          errMsg={errMsg}
          networkInfo={networkInfo}
        >
          <div className="border mb-2 bg-white p-4">
            <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              YieldSet:{" "}
              <AllSelectBtn
                value={filter.ys}
                handleClick={() => this.handleYsChanged("ALL")}
              ></AllSelectBtn>
              <div className="w-1/2">
                {networkId && (
                  <YieldSetsSelector
                    networkId={networkId}
                    handleOnChange={this.handleYsChanged}
                    selectedYieldSet={selectedYieldSet}
                    defaultValue={filter.ys}
                  ></YieldSetsSelector>
                )}
              </div>
            </div>
            <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              Unit Mode:{" "}
              <UnitModeSelector
                modeFilter={filter.mode}
                handleSelectChanged={this.handleModeChanged}
              ></UnitModeSelector>
            </div>

            <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              Unit Status:{" "}
              <UnitStatusSelector
                statusFilter={filter.status}
                handleSelectChanged={this.handleStatusChanged}
              ></UnitStatusSelector>
            </div>

            <button
              type="button"
              onClick={this.searchUnits}
              className={`rounded mt-2 px-6 py-2 font-semibold text-white ${
                isFilterDisabled
                  ? "bg-blue-200"
                  : "shadow bg-blue-400 hover:bg-blue-500"
              }`}
              disabled={isFilterDisabled}
            >
              Get units
            </button>
            {/* <div>{JSON.stringify(filter, null, 4)}</div> */}
          </div>

          {errMsg && <div className="text-red-600">{errMsg}</div>}
          {isLoading && <LoadingUI></LoadingUI>}

          {units && (
            <UnitsSelectionTable
              ref={this.unitsTableRef}
              units={units}
            ></UnitsSelectionTable>
          )}
          {/* {result && <ResultWrapper result={result}></ResultWrapper>} */}
        </NetworkPageWrapper>
      </>
    );
  }
}

class UnitsSelectionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: "ALL",

      selectedUnitIds: [],

      filteredUnits: this.props.units,
    };

    this.searchRef = React.createRef();

    this.handleSelectUnit = this.handleSelectUnit.bind(this);
    this.handleClearSelected = this.handleClearSelected.bind(this);
    this.handleSearchKeyChanged = this.handleSearchKeyChanged.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.getSelectedUnitsByKey = this.getSelectedUnitsByKey.bind(this);
  }

  handleClearAll() {
    // for parent
    this.handleClearSelected();
    this.handleSearchKeyChanged("ALL");
    if (this.searchRef.current) {
      this.searchRef.current.handleClearInput();
    }
  }

  handleSelectUnit(unitId) {
    if (unitId === "ALL") {
      if (
        this.state.selectedUnitIds.length > 0 &&
        this.state.filteredUnits.length === this.state.selectedUnitIds.length
      ) {
        // unselect all
        this.setState({ selectedUnitIds: [] });
        return;
      }
      let newUnits = _.map(this.state.filteredUnits, (u) => {
        return u.adUnitId;
      });
      this.setState({ selectedUnitIds: newUnits });
      return;
    }

    const { selectedUnitIds } = this.state;
    let newUnits = [];
    if (_.includes(selectedUnitIds, unitId)) {
      newUnits = _.filter(selectedUnitIds, (uid) => {
        return uid !== unitId;
      });
    } else {
      newUnits = [...selectedUnitIds, unitId];
    }
    this.setState({ selectedUnitIds: newUnits });
  }

  handleClearSelected() {
    this.setState({ selectedUnitIds: [] });
  }

  handleSearchKeyChanged(sk) {
    this.setState({ searchKey: sk });

    setTimeout(() => {
      if (this.searchRef.current) {
        const inputValue = this.searchRef.current.state.inputValue;
        this.handleSearch(inputValue);
      }
    });
  }

  handleSearch(inputValue) {
    const { units } = this.props;
    const { searchKey, filteredUnits } = this.state;
    let newUnits = [];

    if (inputValue === "") {
      this.setState({ filteredUnits: this.props.units });
      return;
    }

    if (searchKey === "ALL") {
      const allKeys = _.values(SEARCH_KEYS);
      newUnits = _.filter(units, (unit) => {
        let isMatch = false;
        _.forEach(allKeys, (key) => {
          if (this._searchIncludes(unit[key], inputValue)) {
            isMatch = true;
            return;
          }
        });

        return isMatch;
      });
    } else {
      newUnits = _.filter(units, (unit) => {
        let isMatch = false;
        let target = unit[SEARCH_KEYS[searchKey]];
        if (searchKey === SEARCH_KEYS.TAGS) {
          target = target.join(",");
        }
        if (this._searchIncludes(target, inputValue)) {
          isMatch = true;
        }

        return isMatch;
      });
    }

    this.setState({ filteredUnits: newUnits });
  }

  _searchIncludes(target, inputValue) {
    return target && _.toLower(target).includes(_.toLower(inputValue));
  }

  getSelectedUnitsByKey(key) {
    // key: same as searchKey
    const { units } = this.props;
    const { selectedUnitIds } = this.state;

    let results = _.filter(units, (u) => {
      return _.indexOf(selectedUnitIds, u.adUnitId) !== -1;
    });

    results = _.map(results, key);
    return results;
  }

  render() {
    const { units } = this.props;
    const { selectedUnitIds, searchKey, filteredUnits } = this.state;

    if (units.length === 0) {
      return "No units found";
    }

    return (
      <>
        <div className="border rounded bg-white p-2">
          <div className="mb-1 flex items-center gap-2 text-sm">
            <div className="font-semibold">Search key:</div>
            <SearchKeySelector
              filter={searchKey}
              handleSelectChanged={this.handleSearchKeyChanged}
            ></SearchKeySelector>
          </div>
          <ItemsFilter
            ref={this.searchRef}
            handleSearch={this.handleSearch}
          ></ItemsFilter>
        </div>

        <div className="italic text-gray-800">
          Showing <b>{filteredUnits.length}</b> out of {units.length} units
        </div>

        <table
          className="border shadow table w-full text-sm"
          style={{ marginBottom: "500px" }}
        >
          <thead className="border-b bg-gray-200 text-xs text-blue-800">
            <tr>
              <th className="px-2 text-center">
                <input
                  style={{
                    width: "18px",
                    height: "18px",
                    marginTop: "4px",
                  }}
                  type="checkbox"
                  checked={selectedUnitIds.length === filteredUnits.length}
                  onChange={() => this.handleSelectUnit("ALL")}
                ></input>
              </th>
              <th className="border py-1 px-2">Unit</th>
            </tr>
          </thead>
          <tbody className="bg-white font-mono font-normal text-gray-900">
            {filteredUnits &&
              filteredUnits.map((r, i) => {
                return (
                  <tr key={r.adUnitId} className="border-b hover:bg-gray-100">
                    <td className="px-2 text-center">
                      <input
                        style={{
                          width: "18px",
                          height: "18px",
                          marginTop: "4px",
                        }}
                        type="checkbox"
                        checked={selectedUnitIds.indexOf(r.adUnitId) !== -1}
                        onChange={() => this.handleSelectUnit(r.adUnitId)}
                      ></input>
                    </td>
                    <td className="whitespace-no-wrap border py-1 px-2 font-sans font-semibold">
                      <div className="flex items-center gap-2">
                        <div className="border-b border-gray-600">
                          {r.adUnitId}{" "}
                          <span className="mr-1 text-gray-800">{r.name}</span>
                          <UnitStatus unit={r}></UnitStatus>
                          <UnitMode unit={r} version="v2"></UnitMode>
                        </div>
                      </div>

                      <div className="flex items-center gap-2">
                        <div className="font-mono text-xs text-gray-700">
                          {r.extGamAdUnitId}
                        </div>
                        <div className="text-xs font-normal text-gray-700">
                          {`${r.parentPath !== "/" ? r.parentPath + "/" : "/"}${
                            r.code
                          }`}
                        </div>
                      </div>

                      <div className="text-xs font-normal text-gray-700">
                        YS: {r.yieldSet.yieldSetId} {r.yieldSet.name}
                      </div>

                      {r.siteNetworkCode && (
                        <div className="text-xs font-normal text-gray-600">
                          Site Network Code: {r.siteNetworkCode}
                        </div>
                      )}

                      <div
                        style={{ width: "100%" }}
                        className="w-full overflow-x-auto"
                      >
                        {r.tags && (
                          <div className="whitespace-no-wrap my-1 flex flex-wrap gap-2">
                            {r.tags.map((t) => {
                              return (
                                <div
                                  key={t}
                                  className="rounded bg-orange-200 px-2 text-gray-600"
                                  style={{ fontSize: "12px" }}
                                >
                                  {t}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <SelectedUnitsStickyFooter
          selectedUnitIds={selectedUnitIds}
          getSelectedUnitsByKey={this.getSelectedUnitsByKey}
          handleClear={this.handleClearSelected}
        ></SelectedUnitsStickyFooter>
      </>
    );
  }
}

class SelectedUnitsStickyFooter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      copyKey: SEARCH_KEYS.UNIT_ID,
      selectedUnitsString: props.selectedUnitIds.join(", "),
    };

    this.handleCopyKeyChanged = this.handleCopyKeyChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedUnitIds !== this.props.selectedUnitIds) {
      this.setState({
        copyKey: SEARCH_KEYS.UNIT_ID,
        selectedUnitsString: this.props.selectedUnitIds.join(", "),
      });
    }
  }

  handleCopyKeyChanged(key) {
    this.setState({ copyKey: key });

    const newUnits = this.props.getSelectedUnitsByKey(key);
    this.setState({ selectedUnitsString: newUnits.join(", ") });
  }

  render() {
    const { selectedUnitIds, handleClear } = this.props;
    const { copyKey, selectedUnitsString } = this.state;

    return (
      <div className="fixed bottom-0 left-0 w-full border-t-2 bg-white px-4 py-2">
        <div className="flex items-center gap-2 text-gray-700">
          <div className="whitespace-no-wrap w-48 text-right text-sm">
            Selected Units ({selectedUnitIds.length}):
          </div>
          <div className="flex-no-wrap flex gap-1 text-sm">
            {_.values([SEARCH_KEYS.UNIT_ID, SEARCH_KEYS.EXT_UNIT_ID]).map(
              (key) => {
                return (
                  <button
                    key={key}
                    type="button"
                    className={`rounded px-2 py-1 font-semibold ${
                      copyKey === key
                        ? "bg-indigo-100 text-indigo-800"
                        : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                    }`}
                    onClick={() => this.handleCopyKeyChanged(key)}
                  >
                    {key}
                  </button>
                );
              }
            )}
          </div>
          <textarea
            rows="1"
            value={selectedUnitsString}
            readOnly
            className="border rounded w-full p-1 text-xs"
          ></textarea>
          <div>
            <button
              className="rounded overflow-x-auto px-2 py-1 text-sm text-blue-600 hover:bg-blue-100"
              type="button"
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
          <div className="w-24">
            <ClickToCopyWrapper
              copyText={selectedUnitsString}
            ></ClickToCopyWrapper>
          </div>
        </div>
      </div>
    );
  }
}

function AllSelectBtn({
  value,
  allValue = "ALL",
  handleClick,
  buttonText = "ALL",
}) {
  return (
    <button
      type="button"
      className={`rounded mr-1 px-2 py-1 font-semibold ${
        value === allValue
          ? "bg-indigo-100 text-indigo-800"
          : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
      }`}
      onClick={handleClick}
    >
      {buttonText}
    </button>
  );
}

function UnitModeSelector({ modeFilter, handleSelectChanged }) {
  const selectedValue = modeFilter;

  return (
    <div>
      <AllSelectBtn
        value={selectedValue}
        handleClick={() => handleSelectChanged("ALL")}
      ></AllSelectBtn>
      {_.keys(UNIT_MODE).map((mode) => {
        return (
          <button
            key={mode}
            type="button"
            className={`rounded mr-1 px-2 py-1 font-semibold ${
              selectedValue === mode
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
            }`}
            onClick={() => handleSelectChanged(mode)}
          >
            {mode}
          </button>
        );
      })}
    </div>
  );
}

function UnitStatusSelector({ statusFilter, handleSelectChanged }) {
  const selectedValue = statusFilter;

  return (
    <div>
      <AllSelectBtn
        value={selectedValue}
        handleClick={() => handleSelectChanged("ALL")}
        buttonText={"ALL ONBOARDED"}
      ></AllSelectBtn>
      {_.keys(UNIT_STATUS).map((status) => {
        return (
          <button
            key={status}
            type="button"
            className={`rounded mr-1 px-2 py-1 font-semibold ${
              selectedValue === status
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
            }`}
            onClick={() => handleSelectChanged(status)}
          >
            {status}
          </button>
        );
      })}
    </div>
  );
}

function SearchKeySelector({ filter, handleSelectChanged }) {
  const selectedValue = filter;

  return (
    <div>
      <AllSelectBtn
        value={selectedValue}
        handleClick={() => handleSelectChanged("ALL")}
      ></AllSelectBtn>
      {_.keys(SEARCH_KEYS).map((key) => {
        return (
          <button
            key={key}
            type="button"
            className={`rounded mr-1 px-2 py-1 font-semibold ${
              selectedValue === key
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
            }`}
            onClick={() => handleSelectChanged(key)}
          >
            {SEARCH_KEYS[key]}
          </button>
        );
      })}
    </div>
  );
}

class ResultWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    const showKey = "EXT_GAM_UNIT_ID";
    this.state = {
      showKey,
      result: props.result,
      resultItems: this._getResultItems(showKey, props.result),
    };

    this.handleShowKeyChanged = this.handleShowKeyChanged.bind(this);
  }

  _getResultItems(showKey, result) {
    return _.map(
      result,
      showKey === "EXT_GAM_UNIT_ID" ? "extUnitId" : "unitId"
    );
  }

  handleShowKeyChanged(showKey) {
    const { result } = this.state;
    const resultItems = this._getResultItems(showKey, result);
    this.setState({ showKey, resultItems });
  }

  render() {
    const { resultItems, showKey } = this.state;

    return (
      <div className="border bg-white p-4">
        <div className="mb-2 py-2">
          <div className="mb-2 text-sm font-semibold">
            Show:{" "}
            <button
              type="button"
              className={`rounded mr-1 px-2 py-1 font-semibold ${
                showKey === "EXT_GAM_UNIT_ID"
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
              }`}
              onClick={() => this.handleShowKeyChanged("EXT_GAM_UNIT_ID")}
            >
              Ext Gam Unit Id
            </button>
            <button
              type="button"
              className={`rounded mr-1 px-2 py-1 font-semibold ${
                showKey === "GAM_UNIT_ID"
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
              }`}
              onClick={() => this.handleShowKeyChanged("GAM_UNIT_ID")}
            >
              Unit Id
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="italic">{resultItems.length} units</div>

          <div className="rounded px-1 text-sm text-blue-500 hover:bg-blue-100">
            <ClickToCopyWrapper
              copyText={resultItems && resultItems.join(",")}
              buttonText="Copy"
            ></ClickToCopyWrapper>
          </div>
        </div>

        <textarea
          className="border rounded h-64 w-full px-2 py-1"
          value={resultItems && resultItems.join(",")}
          readOnly
        ></textarea>
      </div>
    );
  }
}

const BaseHeader = ({ title }) => {
  return (
    <div className="border-b bg-gray-100 py-2 px-12">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="text-lg font-semibold text-gray-800">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default UnitsSearchViewer;
