import React from "react";
import _ from "lodash";
import moment from "moment-timezone";

import DatePicker from "react-datepicker";
import { notify } from "react-notify-toast";

import { PublisherAPI } from "apis";
import PublishersTable from "./PublishersTable";
import LoadingUI from "../../../common/LoadingUI";

class UpdatePublisherTrialStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      publisherId: null,
      tempPublisher: null,
      errMsg: "",

      expireDate: null,
      billableStartDate: null,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
  }

  async componentDidMount() {
    try {
      const { publisherId, accountStatus } = this.props.publisher;

      this.setState({
        publisherId,
        tempPublisher: this.props.publisher,

        expireDate: accountStatus.trialStatus.expDate,
        billableStartDate: accountStatus.trialStatus.billableStartDate,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleDateSelect = (date, key) => {
    if (date) {
      const d = moment(date).format("YYYY-MM-DD");
      const dd = new Date(d).toISOString().substring(0, 10);
      const parseDate = `${dd}T00:00:00.000Z`;
      this.setState({
        [key]: parseDate,
      });
    } else {
      this.setState({
        [key]: null,
      });
    }
  };

  async handleUpdate() {
    try {
      const { publisherId, expireDate, billableStartDate } = this.state;
      const { handleUpdateData, handleClose } = this.props;
      if (!expireDate && !billableStartDate) {
        throw "Missing 'Expire Date', or 'Billable Start Date'!";
      }

      const params = {
        publisherId,
        expireDate,
        billableStartDate: billableStartDate || null,
      };

      this.setState({
        isSaving: true,
      });

      const {
        result: {
          data: {
            update: { publisher },
          },
        },
      } = await PublisherAPI.updatePublisherTrialStatus(params);

      const list = await PublisherAPI.getPublishersDetail(
        publisher.publisherId
      );

      if (list.length === 0) throw "Publisher not found!";

      const newPublisher = list[0];

      this.setState({
        isSaving: false,
        errMsg: "",
        tempPublisher: newPublisher,
      });

      handleUpdateData(newPublisher);

      notify.show("Trial status updated!", "success");
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
      });
    }
  }

  isDisabled = () => {
    const { tempPublisher, expireDate, billableStartDate } = this.state;
    return (
      tempPublisher.accountStatus.trialStatus.expDate === expireDate &&
      tempPublisher.accountStatus.trialStatus.billableStartDate ===
        billableStartDate
    );
  };

  render() {
    const {
      tempPublisher,
      expireDate,
      billableStartDate,
      isLoading,
      isSaving,
      errMsg,
    } = this.state;

    const { handleClose } = this.props;

    return (
      <div>
        <div className="bg-white pb-4">
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            Update Publisher Trial Status
          </div>

          {tempPublisher && (
            <PublishersTable
              items={[tempPublisher]}
              hideAction={true}
            ></PublishersTable>
          )}
        </div>

        {isLoading && <LoadingUI></LoadingUI>}

        {tempPublisher && (
          <div className="bg-gray-200 p-4">
            <div>
              <div className="mb-4">
                <label className="font-semibold text-gray-800">
                  Trial Expire Date:
                </label>
                <div>
                  <DatePicker
                    selected={expireDate && new Date(expireDate)}
                    dateFormat="yyyy-MM-dd"
                    className="border rounded w-64 p-2"
                    placeholderText="Click to select a date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onSelect={(date) =>
                      this.handleDateSelect(date, "expireDate")
                    }
                  />
                </div>
              </div>

              <div>
                <label className="font-semibold text-gray-800">
                  Billable Start Date:
                </label>
                <div>
                  <DatePicker
                    selected={billableStartDate && new Date(billableStartDate)}
                    dateFormat="yyyy-MM-dd"
                    className="border rounded w-64 p-2"
                    placeholderText="Click to select a date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(update) => {
                      return this.handleDateSelect(update, "billableStartDate");
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 flex flex-row-reverse items-center">
              <div>
                <button
                  type="button"
                  disabled={this.isDisabled()}
                  className={`rounded px-4 py-2 font-semibold text-white ${
                    this.isDisabled()
                      ? "cursor-not-allowed bg-blue-300"
                      : "shadow bg-blue-500 hover:bg-blue-700"
                  }`}
                  onClick={() => this.handleUpdate()}
                >
                  {isSaving ? "Saving..." : "Update"}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  disabled={isSaving}
                  className={`px-4 py-2 text-blue-700 ${
                    isSaving ? "cursor-not-allowed" : "text-blue-400"
                  }`}
                  onClick={() => handleClose()}
                >
                  Back
                </button>
              </div>
              <div className="text-red-600">{errMsg}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UpdatePublisherTrialStatus;
