import React from "react";
import _ from "lodash";
import CustomSetTargetingFormatterV2 from "../../../control-center/auto-pilot/config/CustomSetTargetingFormatterV2";
import { GAM_DEVICE_CATEGORY } from "../../../../constants/GAMDeviceCategory";
import { GAM_OS } from "../../../../constants/GAMOS";
import { GAM_COUNTRY } from "../../../../constants/GAMCountry";
import { GAM_BROWSER } from "constants/GAMBrowser";
import {
  GAM_INVENTORY_FORMATS_NAME,
  GAM_INVENTORY_TYPES_NAME,
  GAM_VIDEO_POSITIONS_NAME,
} from "./Constants";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";
import CustomSetTargetingFormatter from "components/control-center/auto-pilot/config/CustomSetTargetingFormatter";

class UprTargetingsView extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      targetingSection = {},
      units,
      virtualPlacements,
      networkId,
    } = this.props;

    const {
      inventory,
      deviceCategory, // { includes, excludes }
      os, // { includes, excludes }
      customs, // []
      country, // { includes, excludes }
      browser, // { includes, excludes }
      inventoryTypes, // []
      videoPositions, // []
      inventoryFormat, // { includes, excludes }
      inventoryUrl, // { includes, excludes }
      inventorySize, // { includes, excludes }
      mobileApplication, // { includes, excludes }
    } = targetingSection;

    // custom upr: unitIds, virtualPlacementIds, excludedUnitIds,
    // prebid settings: units, placements,

    const unitIds =
      _.get(inventory, ["units", "includes"]) || _.get(inventory, "unitIds");
    const placementIds =
      _.get(inventory, ["placements", "includes"]) ||
      _.get(inventory, "placementIds");
    const virtualPlacementIds =
      _.get(inventory, ["virtualPlacements", "includes"]) ||
      _.get(inventory, "virtualPlacementIds");
    const excludedUnitIds =
      _.get(inventory, ["units", "excludes"]) ||
      _.get(inventory, "excludedUnitIds");

    const mcm = _.get(inventory, "mcm");

    const unitsById = _.keyBy(units, "extGamUnitId");
    let includedUnits =
      unitIds && !_.isEmpty(unitIds)
        ? {
            onboarded: [],
            notOnboarded: [],
            totalCount: 0,
            extGamUnitIds: [],
            gamUnitIds: [],
          }
        : null;
    _.forEach(unitIds, (unitId) => {
      if (unitsById[unitId]) {
        includedUnits.onboarded.push(unitsById[unitId]);
        includedUnits.gamUnitIds.push(unitsById[unitId].unitId);
      } else {
        includedUnits.notOnboarded.push(unitId);
      }
      includedUnits.extGamUnitIds.push(unitId);
      includedUnits.totalCount++;
    });

    let excludedUnits =
      excludedUnitIds && !_.isEmpty(excludedUnitIds)
        ? {
            onboarded: [],
            notOnboarded: [],
            totalCount: 0,
            extGamUnitIds: [],
            gamUnitIds: [],
          }
        : null;
    _.forEach(excludedUnitIds, (unitId) => {
      if (unitsById[unitId]) {
        excludedUnits.onboarded.push(unitsById[unitId]);
        excludedUnits.gamUnitIds.push(unitsById[unitId].unitId);
      } else {
        excludedUnits.notOnboarded.push(unitId);
      }
      excludedUnits.extGamUnitIds.push(unitId);
      excludedUnits.totalCount++;
    });
    // let inventoryExcludeUnits = _.map(excludedUnitIds, (uid) => {
    //   return unitsById[uid];
    // });
    const vpById = _.keyBy(virtualPlacements, "virtualPlacementId");
    let inventoryVps = _.map(virtualPlacementIds, (id) => {
      return vpById[id];
    });

    return (
      <div className="flex flex-col gap-4">
        {inventory && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">Inventory</div>
            <div>
              <InventoryView
                inventory={inventory}
                includedUnits={includedUnits}
                excludedUnits={excludedUnits}
                placementIds={placementIds}
                inventoryVps={inventoryVps}
                mcm={mcm}
              ></InventoryView>
            </div>
          </div>
        )}

        {os && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">OS</div>
            <div>
              <SegmentTarget targetType="OS" targetData={os}></SegmentTarget>
            </div>
          </div>
        )}

        {deviceCategory && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">Device Category</div>
            <div>
              <SegmentTarget
                targetType="DEVICE_CATEGORY"
                targetData={deviceCategory}
              ></SegmentTarget>
            </div>
          </div>
        )}

        {country && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">Country</div>
            <div>
              <SegmentTarget
                targetType="COUNTRY"
                targetData={country}
              ></SegmentTarget>
            </div>
          </div>
        )}

        {browser && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">Browser</div>
            <div>
              <SegmentTarget
                targetType="BROWSER"
                targetData={browser}
              ></SegmentTarget>
            </div>
          </div>
        )}

        {mobileApplication && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">
              Mobile Application
            </div>
            <div>
              <SegmentTarget
                targetType="MOBILE_APPLICATION"
                targetData={mobileApplication}
              ></SegmentTarget>
            </div>
          </div>
        )}

        {inventoryTypes && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">Inventory Types</div>
            <div>
              <SegmentTarget
                targetType="INVENTORY_TYPES"
                targetData={inventoryTypes}
              ></SegmentTarget>
            </div>
          </div>
        )}

        {videoPositions && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">Video Positions</div>
            <div>
              <SegmentTarget
                targetType="VIDEO_POSITIONS"
                targetData={videoPositions}
              ></SegmentTarget>
            </div>
          </div>
        )}

        {inventoryFormat && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">Inventory Formats</div>
            <div>
              <SegmentTarget
                targetType="INVENTORY_FORMATS"
                targetData={inventoryFormat}
              ></SegmentTarget>
            </div>
          </div>
        )}

        {inventoryUrl && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">Inventory URL</div>
            <div>
              <SegmentTarget
                targetType="INVENTORY_URLS"
                targetData={inventoryUrl}
              ></SegmentTarget>
            </div>
          </div>
        )}

        {inventorySize && (
          <div className="border-b">
            <div className="font-semibold text-gray-900">Inventory Sizes</div>
            <div>
              <SegmentTarget
                targetType="INVENTORY_SIZES"
                targetData={inventorySize}
              ></SegmentTarget>
            </div>
          </div>
        )}

        {customs && (
          <div className="border-b">
            <div
              className={`${
                _.isArray(customs[0]) && "-mb-4"
              } font-semibold text-gray-900`}
            >
              Customs (
              {customs
                .map((c) => {
                  return c.length;
                })
                .join(", ")}
              )
            </div>
            <div className="text-sm">
              {_.isArray(customs[0]) ? (
                <CustomSetTargetingFormatterV2
                  networkId={networkId}
                  setsOfCustom={customs}
                  showActionButtons={false}
                ></CustomSetTargetingFormatterV2>
              ) : (
                <CustomSetTargetingFormatter
                  networkId={networkId}
                  setsOfCustom={customs}
                  showActionButtons={false}
                ></CustomSetTargetingFormatter>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

class SegmentTarget extends React.PureComponent {
  render() {
    const { targetData, targetType } = this.props;

    const hasIncludeExclude =
      _.has(targetData, "includes") || _.has(targetData, "excludes");
    let isInclude = false;
    let op = "";
    let targets = [];
    if (hasIncludeExclude) {
      isInclude = targetData.includes;
      op = isInclude ? "Includes" : "Excludes";
      targets = isInclude ? targetData.includes : targetData.excludes;
    } else {
      targets = targetData;
    }

    targets = _.map(targets, (t) => {
      if (targetType === "DEVICE_CATEGORY") {
        return GAM_DEVICE_CATEGORY[t];
      }
      if (targetType === "OS") {
        return GAM_OS[t];
      }
      if (targetType === "COUNTRY") {
        return GAM_COUNTRY[t];
      }
      if (targetType === "BROWSER") {
        return GAM_BROWSER[t];
      }
      if (targetType === "INVENTORY_TYPES") {
        return GAM_INVENTORY_TYPES_NAME[t];
      }
      if (targetType === "VIDEO_POSITIONS") {
        return GAM_VIDEO_POSITIONS_NAME[t];
      }
      if (targetType === "INVENTORY_FORMATS") {
        return GAM_INVENTORY_FORMATS_NAME[t];
      }

      return t;
    });

    return (
      <div className="text-sm">
        {targetData && (
          <div>
            {hasIncludeExclude && (
              <div
                className={`${
                  isInclude ? "text-green-500" : "text-red-500"
                } font-medium`}
              >
                {op}
              </div>
            )}
            <div>
              {targets.map((value, i) => {
                return (
                  <span key={i} className="bg-gray-200 px-1">
                    {value}
                    {targets.length - 1 === i ? "" : ", "}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function InventoryView({
  inventory,
  includedUnits,
  excludedUnits,
  placementIds,
  inventoryVps,
  mcm,
}) {
  if (!inventory) return "-";

  let unitsView = "";
  let excludedUnitsView = "";
  let placementsView = "";
  let virtualPlacementsView = "";
  let mcmView = "";

  if (includedUnits) {
    unitsView = (
      <div className="text-sm text-gray-800">
        <div className="mb-1">
          <div className="flex items-center justify-between">
            <div className="font-semibold">
              Units ({includedUnits.totalCount})
            </div>

            <div className="flex gap-1 text-xs text-blue-500">
              <ClickToCopyWrapper
                copyText={includedUnits.extGamUnitIds.join(",")}
                buttonText="Copy Ext Gam Unit IDs"
              ></ClickToCopyWrapper>
              {includedUnits.gamUnitIds.length > 0 && (
                <ClickToCopyWrapper
                  copyText={includedUnits.gamUnitIds.join(",")}
                  buttonText="Copy Unit IDs"
                ></ClickToCopyWrapper>
              )}
            </div>
          </div>
          <div className="leading-none text-green-500">Includes</div>
        </div>

        <div style={{ maxHeight: "212px", overflow: "scroll" }}>
          {includedUnits.onboarded.map((unit) => {
            return (
              <div
                key={unit.unitId}
                className="mr-1 mb-1 inline-block bg-gray-200 px-1"
              >
                {unit.unitId} {unit.name} ({unit.extGamUnitId})
              </div>
            );
          })}

          <div>
            {includedUnits.notOnboarded.map((unitId) => {
              return (
                <div
                  key={unitId}
                  className="mr-1 mb-1 inline-block bg-gray-300 px-1"
                >
                  {unitId}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  if (excludedUnits) {
    excludedUnitsView = (
      <div className="text-sm text-gray-800">
        <div className="mb-1">
          <div className="flex items-center justify-between">
            <div className="font-semibold">
              Units ({excludedUnits.totalCount})
            </div>

            <div className="flex gap-1 text-xs text-blue-500">
              <ClickToCopyWrapper
                copyText={excludedUnits.extGamUnitIds.join(",")}
                buttonText="Copy Ext Gam Unit IDs"
              ></ClickToCopyWrapper>
              {excludedUnits.gamUnitIds.length > 0 && (
                <ClickToCopyWrapper
                  copyText={excludedUnits.gamUnitIds.join(",")}
                  buttonText="Copy Unit IDs"
                ></ClickToCopyWrapper>
              )}
            </div>
          </div>
          <div className="leading-none text-red-500">Excludes</div>
        </div>

        <div style={{ maxHeight: "212px", overflow: "scroll" }}>
          {excludedUnits.onboarded.map((unit) => {
            return (
              <div
                key={unit.unitId}
                className="mr-1 mb-1 inline-block bg-gray-200 px-1"
              >
                {unit.unitId} {unit.name} ({unit.extGamUnitId})
              </div>
            );
          })}

          <div>
            {excludedUnits.notOnboarded.map((unitId) => {
              return (
                <div
                  key={unitId}
                  className="mr-1 mb-1 inline-block bg-gray-300 px-1"
                >
                  {unitId}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  if (placementIds && !_.isEmpty(placementIds)) {
    placementsView = (
      <div className="text-sm text-gray-800">
        <div className="font-semibold">Placements({placementIds.length})</div>

        <div>
          {placementIds.map((pid) => {
            return (
              <div
                key={pid}
                className="mr-1 mb-1 inline-block bg-gray-200 px-1"
              >
                {pid}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  if (inventoryVps && !_.isEmpty(inventoryVps)) {
    virtualPlacementsView = (
      <div className="text-sm text-gray-800">
        <div className="font-semibold">
          Virtual Placements({inventoryVps.length})
        </div>

        <div>
          {inventoryVps.map((item) => {
            return (
              <div
                key={item.virtualPlacementId}
                className="mr-1 mb-1 inline-block bg-gray-200 px-1"
              >
                [{item.virtualPlacementId}] {item.name} (
                {item.gamUnitIds.length} units)
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  if (mcm && !_.isEmpty(mcm)) {
    mcmView = (
      <div className="text-sm text-gray-800">
        <div className="font-semibold">MCM({mcm.length})</div>

        <div>
          {mcm.map((mcmId, index) => {
            return (
              <div
                key={index}
                className="mr-1 mb-1 inline-block bg-gray-200 px-1"
              >
                {JSON.stringify(mcmId)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div>
      {unitsView}
      {excludedUnitsView}
      {placementsView}
      {virtualPlacementsView}
      {mcmView}
    </div>
  );
}

export default UprTargetingsView;
