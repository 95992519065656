import React from "react";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";
import _ from "lodash";
import { NetworkAPI } from "apis";
import NetworkInfoHeader from "components/ops-mgmt/common/NetworkInfoHeader";
import NetworkGamUprViewer from "./gam-upr/NetworkGamUprViewer";
import FirstLookRulesViewer from "./first-look-rules/FirstLookRulesViewer";

class PricingRulesViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      networkId: null,
      networkInfo: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { networkId } = this.props.match.params;
    if (!networkId) {
      this.setState({ errMsg: "Missing Network ID" });
    } else {
      document.title = `${networkId} GAM UPRs | YB Observer`;
      this.setState({ networkId });
    }

    const networkInfo = await NetworkAPI.getNetworkInfo({
      networkId,
    });

    const units = await NetworkAPI.getUnitsByNetworkId({ networkId });
    const virtualPlacements = await NetworkAPI.getNetworkVirtualPlacements({
      networkId,
    });

    this.setState({
      networkId,
      networkInfo,

      units,
      virtualPlacements,
    });
  }

  render() {
    const { networkId, networkInfo, units, virtualPlacements } = this.state;

    const paths = {
      uprs: `/network/:networkId/pricing-rules/uprs`,
      firstLookRules: `/network/:networkId/pricing-rules/first-look-rules`,
    };

    return (
      <div>
        {networkInfo && (
          <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>
        )}

        {networkId && networkInfo && (
          <>
            <TabHeader networkId={networkId}></TabHeader>

            <div className="min-h-screen bg-gray-200 px-12 py-8">
              <Switch>
                <Route exact path={paths.uprs}>
                  <NetworkGamUprViewer
                    networkInfo={networkInfo}
                    networkId={networkId}
                    units={units}
                    virtualPlacements={virtualPlacements}
                  ></NetworkGamUprViewer>
                </Route>
                <Route exact path={paths.firstLookRules}>
                  <FirstLookRulesViewer
                    networkInfo={networkInfo}
                    networkId={networkId}
                    units={units}
                    virtualPlacements={virtualPlacements}
                  ></FirstLookRulesViewer>
                </Route>
              </Switch>
            </div>
          </>
        )}
      </div>
    );
  }
}

const TabHeader = ({ networkId }) => {
  const activeTabClass =
    "py-1 px-6 mr-2 bg-gray-200 font-semibold text-gray-900 rounded-t-lg border-b-4 border-gray-200";
  const inactiveTabClass =
    "py-1 px-6 mr-2 rounded-t-lg font-semibold text-gray-500 bg-white hover:bg-gray-100 cursor-pointer";

  const paths = {
    uprs: `/network/${networkId}/pricing-rules/uprs`,
    firstLookRules: `/network/${networkId}/pricing-rules/first-look-rules`,
  };

  const TABS = {
    UPRS: {
      title: "UPRs",
      path: paths.uprs,
    },
    FIRST_LOOK_RULES: {
      title: "First Look Rules",
      path: paths.firstLookRules,
    },
  };
  return (
    <div className="flex bg-white px-12 pt-2">
      <div className="flex items-center gap-4">
        <div className="text-lg font-semibold text-gray-800">
          GAM Pricing Rules
        </div>
        <div>
          {_.values(TABS).map((tab) => {
            return (
              <NavLink
                key={tab.title}
                to={tab.path}
                className={inactiveTabClass}
                // activeClassName="border-b border-blue-500"
                activeClassName={activeTabClass}
              >
                {tab.title}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withRouter(PricingRulesViewer);
