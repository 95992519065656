import React from "react";
import _ from "lodash";

import { formatMoneyWithCurrency } from "components/intowow-business/platform-index/PlatformIndexReportsHelper";
import UprPriceSectionPopover from "components/ops-mgmt/account/custom-upr/UprPriceSectionPopover";

const PRICE_TYPE = {
  0: "Floor",
  1: "Target CPM",
  2: "LGO", // let google optimize
  "-1": "Use Brand Setting",
};
function UprPriceSection({ item, networkId }) {
  let {
    priceType,
    price,
    brandedPriceSettings,
    branded,
    currency = "USD",
  } = item;
  if (currency === "") currency = "USD";

  return (
    <div>
      <div className="-mx-2 px-2">
        <div className="text-xs font-medium leading-none text-gray-600">
          {PRICE_TYPE[priceType]}
        </div>
        {branded === "BLOCKED" ? (
          <div className="leading-none text-red-500">Blocked</div>
        ) : (
          <>
            {priceType !== -1 && priceType !== 2 && (
              <div className="font-mono">
                {formatMoneyWithCurrency({
                  currency: currency,
                  value: price,
                })}
              </div>
            )}
          </>
        )}
      </div>
      {brandedPriceSettings && !_.isEmpty(brandedPriceSettings) && (
        <div className="border-t">
          <UprPriceSectionPopover
            networkId={networkId}
            currency={currency}
            targetingSection={item}
            triggerElement={
              <div className="cursor-pointer hover:underline">
                <BrandedPriceView
                  item={item}
                  currency={currency}
                ></BrandedPriceView>
              </div>
            }
          ></UprPriceSectionPopover>
        </div>
      )}
    </div>
  );
}

function BrandedPriceView({ item, currency }) {
  const { brandedPriceSettings } = item;

  return (
    <div>
      <span className="rounded bg-green-200 px-2 text-xs text-green-900">
        Branded ({brandedPriceSettings.length})
      </span>
    </div>
  );
}

export default UprPriceSection;
