import React from "react";
import moment from "moment-timezone";
import UnitStatus from "../common/UnitStatus";
import UnitMode from "../common/UnitMode";
import SegmentModeIndicator from "../common/SegmentModeIndicator";
import PopupWrapper from "../common/PopupWrapper";
import SegmentBasicTable from "../common/SegmentBasicTable";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
import GoToAPConfigButton from "../common/GoToAPConfigButton";
import GoToAnatomyButton from "../common/GoToAnatomyButton";
import ReactTooltip from "react-tooltip";
import NumberFormat from "../common/NumberFormat";
import DateTimeFormatter from "../common/DateTimeFormatter";
import GoToCSTAnatomyButton from "components/common/GoToCSTAnatomyButton";
// import FavoriteButton from "./FavoriteButton";

class UnitInsightHeader extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   isMouseOverFollowBtn: false,
    // };
  }

  render() {
    const { unit, config, segments, updatedAt } = this.props;

    const updateTime = moment(updatedAt).format("YYYY-MM-DD HH:mm");
    const updateTimeAgo = moment(updatedAt).fromNow();

    return (
      <>
        <div className="border-b px-4 py-2">
          <div className="mt-1 py-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div>
                  <div className="font-semibold text-blue-800">
                    <span className="mr-1 text-blue-700">
                      {unit.gamNetwork.gamNetworkId}
                    </span>
                    {unit.gamNetwork.name}
                  </div>
                  <div
                    className="text-xs leading-none text-gray-600"
                    style={{ maxWidth: "200px" }}
                  >
                    YS: {unit.yieldSet.yieldSetId} {unit.yieldSet.name}
                  </div>
                </div>

                <div className="border-l ml-3 pl-3">
                  <div className="flex">
                    <div className="flex flex-col">
                      <div className="flex text-lg leading-none">
                        <div className="mr-2">{unit.gamUnitId}</div>
                        <div className="font-black">
                          {unit.name}
                          {unit.deletedAt && (
                            <span className="font-normal">
                              <span
                                className="rounded mx-1 bg-red-300 px-1 text-xs font-bold uppercase text-red-800"
                                data-tip
                                data-for={`${unit.gamUnitId}-deleted-at`}
                              >
                                Deleted
                              </span>
                              <ReactTooltip
                                type="dark"
                                effect="solid"
                                place="top"
                                id={`${unit.gamUnitId}-deleted-at`}
                              >
                                Deleted at:{" "}
                                <DateTimeFormatter
                                  datetime={unit.deletedAt}
                                ></DateTimeFormatter>
                              </ReactTooltip>
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="mt-1 flex items-center text-xs leading-none text-gray-600">
                        <div
                          className="mr-4 flex items-center"
                          title="Ext Unit ID"
                        >
                          {unit.extUnitId}
                          <ClickToCopyWrapper
                            copyText={unit.extUnitId}
                          ></ClickToCopyWrapper>
                        </div>

                        <div
                          className="mr-4 flex items-center justify-start"
                          title="Ext Unit Path"
                        >
                          <span
                            className="whitespace-no-wrap overflow-x-auto"
                            style={{ maxWidth: "400px" }}
                          >
                            {unit.extUnitPath}
                          </span>
                          <ClickToCopyWrapper
                            copyText={unit.extUnitPath}
                          ></ClickToCopyWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <div className="flex items-center justify-end gap-4 text-sm">
                    <UnitStatus unit={unit}></UnitStatus>
                    <UnitMode unit={unit}></UnitMode>
                  </div>
                </div>

                <div className="flex items-end">
                  <AdPodsSection
                    enableAdPods={config.enable_ad_pods}
                  ></AdPodsSection>

                  <InspectionSection
                    unitId={unit.gamUnitId}
                    forceInspection={config.force_inspection}
                  ></InspectionSection>

                  <DebugModeSection
                    enableDispatcherDebug={config.enable_dispatcher_debugging}
                  ></DebugModeSection>

                  <ForerunnerConfigSection
                    value={config.minimum_daily_request_volume}
                  ></ForerunnerConfigSection>

                  <BmSection
                    unitId={unit.gamUnitId}
                    bpm={config.benchmark_performance_multiplier}
                    enableAbuse={config.enable_benchmark_tfcd_abuse}
                    bmAbuseSizes={config.benchmark_abuse_sizes}
                  ></BmSection>

                  <MaxTrafficRatioSection
                    minTrafficRatio={config.minimum_traffic_ratio}
                    maxTrafficRatio={config.maximum_traffic_ratio}
                  ></MaxTrafficRatioSection>

                  <UnitChangeTimeSection unit={unit}></UnitChangeTimeSection>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 flex justify-between">
            <div
              className="flex gap-4"
              // style={{ width: "30%", border: "1px solid red" }}
            >
              <div style={{ minWidth: "170px" }}>
                <span className="block text-xs font-semibold uppercase leading-none text-gray-600">
                  Request Types ({unit.requestTypes.length})
                </span>

                <RequestTypesSection
                  requestTypes={unit.requestTypes}
                ></RequestTypesSection>
              </div>

              <div style={{ minWidth: "170px" }}>
                <span className="block text-xs font-semibold uppercase leading-none text-gray-600">
                  Demand Types ({unit.demandTypes.length})
                </span>
                <div
                  className="whitespace-no-wrap my-1 mr-1 flex overflow-x-auto"
                  style={{
                    maxWidth: "400px",
                  }}
                >
                  <DemandTypesSection
                    demandTypes={unit.demandTypes}
                  ></DemandTypesSection>
                </div>
              </div>
            </div>

            <div className="text-sm">
              <span className="block text-xs font-semibold uppercase leading-none text-gray-600">
                Segments ({segments ? segments.length : 0})
              </span>
              {segments && segments.length > 0 && (
                <PopupWrapper
                  place="bottom center"
                  triggerType="hover"
                  hideArrow={true}
                  triggerElement={
                    <div
                      className="flex overflow-x-auto"
                      style={{
                        maxWidth: "400px",
                      }}
                    >
                      <div className="rounded hover:shadow flex bg-gray-300">
                        {segments.map((s) => (
                          <div
                            key={s.segmentId}
                            className="rounded mx-1 flex items-center gap-1 p-1 font-mono"
                          >
                            <SegmentModeIndicator
                              mode={s.mode}
                            ></SegmentModeIndicator>
                            <span
                              className={`leading-none ${
                                s.srConfig ? "border-b-2 border-purple-400" : ""
                              }`}
                            >
                              {s.segmentId}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                  popupElement={
                    <div style={{ maxWidth: "800px" }}>
                      <SegmentBasicTable
                        segments={segments}
                      ></SegmentBasicTable>
                    </div>
                  }
                ></PopupWrapper>
              )}
            </div>

            <div>
              <div className="flex justify-end gap-2">
                <GoToAnatomyButton unitId={unit.gamUnitId}></GoToAnatomyButton>
                <GoToCSTAnatomyButton
                  unitId={unit.gamUnitId}
                ></GoToCSTAnatomyButton>
                <GoToAPConfigButton
                  unitId={unit.gamUnitId}
                ></GoToAPConfigButton>
              </div>

              <div className="mt-2 text-xs leading-none text-gray-600">
                Data last updated at{" "}
                <span className="font-semibold">
                  {updateTime} ({updateTimeAgo})
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class UnitChangeTimeSection extends React.PureComponent {
  render() {
    const { unit } = this.props;

    return (
      <>
        <div
          data-tip
          data-for={`unit-changed-at-${unit.gamUnitId}`}
          className="whitespace-no-wrap border-l px-1 text-xs text-gray-700"
        >
          <>
            Changes made{" "}
            <span className="text-gray-900">
              <DateTimeFormatter
                fromNowOnly={true}
                datetime={unit.lastChangedAt}
              ></DateTimeFormatter>
            </span>
          </>
        </div>

        <ReactTooltip
          id={`unit-changed-at-${unit.gamUnitId}`}
          type="dark"
          effect="solid"
          // place="bottom"
        >
          <table>
            <tbody>
              <tr>
                <td className="pr-2 text-right">Created At</td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.createdAt}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>

              <tr>
                <td className="pr-2 text-right">Updated At</td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.updatedAt}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>

              <tr>
                <td className="pr-2 text-right">
                  Auto Pilot Config Updated At
                </td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.configUpdatedAt}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </ReactTooltip>
      </>
    );
  }
}

class InspectionSection extends React.PureComponent {
  render() {
    const { forceInspection } = this.props;
    if (forceInspection) {
      return (
        <div className="border-l px-1 text-xs" title="Force Inspection">
          {forceInspection && (
            <span className="rounded bg-orange-400 px-2 font-bold text-orange-100">
              INSP
            </span>
          )}
        </div>
      );
    } else {
      return "";
    }
  }
}

class AdPodsSection extends React.PureComponent {
  render() {
    const { enableAdPods } = this.props;

    return (
      <>
        {enableAdPods ? (
          <div className="border-l px-1 text-xs">
            <span className="font-bold text-purple-600">ADPODS</span>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

class DebugModeSection extends React.PureComponent {
  render() {
    const { enableDispatcherDebug } = this.props;
    return enableDispatcherDebug ? (
      <div className="border-l px-1 text-xs">
        <span className="rounded bg-pink-500 px-2 font-bold text-pink-100">
          DEBUG
        </span>
      </div>
    ) : (
      ""
    );
  }
}

class MaxTrafficRatioSection extends React.PureComponent {
  render() {
    const { maxTrafficRatio, minTrafficRatio } = this.props;
    return (
      <div className="border-l px-1 text-xs">
        Traffic:{" "}
        <span className="text-gray-600">
          <NumberFormat value={minTrafficRatio} format="0,0%"></NumberFormat>
        </span>
        <span className="px-1">-</span>
        <span className="text-gray-600">
          <NumberFormat value={maxTrafficRatio} format="0,0%"></NumberFormat>
        </span>
      </div>
    );
  }
}

class ForerunnerConfigSection extends React.PureComponent {
  render() {
    const { value } = this.props;
    return (
      <div className="border-l px-1 text-xs">
        <span
          className="text-gray-700"
          data-tip
          data-for={`forerunner-config-tip`}
        >
          Min Req: <NumberFormat value={value} format="0,0"></NumberFormat>
        </span>
        <ReactTooltip
          id={`forerunner-config-tip`}
          type="dark"
          effect="solid"
          place="bottom"
        >
          Forerunner's Minimum Daily Request Volume
        </ReactTooltip>
      </div>
    );
  }
}

class BmSection extends React.PureComponent {
  render() {
    const { unitId, bpm, enableAbuse, bmAbuseSizes } = this.props;
    /*
    bmAbuseSizes=[
      [
        "fluid",
        [
          1,
          1
        ]
      ]
    ]
    */
    let abuseSizesString = "None";
    if (bmAbuseSizes) {
      let sizes = bmAbuseSizes[0];
      abuseSizesString = sizes
        .map((s) => {
          if (typeof s === "string") {
            return s;
          } else {
            return s.join("x");
          }
        })
        .join(", ");
    }

    return (
      <div
        className="border-l px-1 text-xs"
        // title="Benchmark Performance Multiplier"
      >
        BPM:{" "}
        {bpm < 1 ? (
          <>
            <span
              className="font-bold text-orange-600"
              data-tip
              data-for={`bpm-label-${unitId}`}
            >
              <NumberFormat value={bpm} format="0,0%"></NumberFormat>
            </span>
            <ReactTooltip
              id={`bpm-label-${unitId}`}
              type="dark"
              effect="solid"
              place="top"
            >
              Enable benchmark tfcd abuse:{" "}
              <b>{enableAbuse ? "true" : "false"}</b>
              <br></br>
              Benchmark Abuse Sizes: <b>{abuseSizesString}</b>
            </ReactTooltip>
          </>
        ) : (
          <span className="text-gray-600">
            <NumberFormat value={bpm} format="0,0%"></NumberFormat>
          </span>
        )}
      </div>
    );
  }
}

class RequestTypesSection extends React.PureComponent {
  render() {
    const { requestTypes } = this.props;
    return (
      <div className="my-1 flex">
        {requestTypes.map((d) => {
          if (d === "") return "";
          return (
            <div
              key={d}
              className="rounded mr-2 bg-gray-300 px-2 text-xs text-gray-700"
              title={d}
            >
              {d === "Google Publisher Tag" ? "GPT" : d}
            </div>
          );
        })}
      </div>
    );
  }
}
class DemandTypesSection extends React.PureComponent {
  render() {
    const { demandTypes } = this.props;

    return (
      <>
        {demandTypes.map((d) => {
          return (
            <div
              key={d}
              className="rounded mr-2 bg-gray-300 px-2 text-xs text-gray-700"
            >
              {d}
            </div>
          );
        })}
      </>
    );
  }
}
export default UnitInsightHeader;
