export const subFilterTypes = {
  ALL: "ALL",
  TRIAL: "TRIAL",
  PRODUCT: "PRODUCT",
}

export const trialTypes = {
  BILLABLE: "BILLABLE",
  IN_FREE_TRIAL: "IN_TRIAL",
  TRIAL_EXPIRED: "TRIAL_EXPIRED",
  OTHERS: "OTHERS"
};
