import React from "react";

const UNIT_MODE = {
  INITIAL: "INITIAL",
  NORMAL: "NORMAL",
  WATCH_LIST: "WATCH_LIST",
  SUSPENDED: "SUSPENDED",
};

class UnitMode extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { unit, showName, version = "v1" } = this.props;

    if (version === "v2") {
      let color = "text-gray-700"; // UNIT_MODE = NORMAL

      switch (unit.mode) {
        // new
        case UNIT_MODE.INITIAL: {
          color = "text-orange-500";
          break;
        }

        case UNIT_MODE.WATCH_LIST: {
          color = "text-red-600";
          break;
        }

        case UNIT_MODE.SUSPENDED: {
          color = "text-purple-700";
          break;
        }
      }

      return (
        <span className={`rounded bg-gray-200 px-1 py-0 text-sm ${color}`}>
          {unit.mode}
        </span>
      );
    }

    switch (unit.mode) {
      // old
      case 1: {
        return (
          <>
            <span
              role="img"
              aria-label={unit.mode}
              title="Boosting"
              style={{ fontSize: "12px" }}
            >
              🚀
            </span>
            {showName && "Boosting"}
          </>
        );
      }
      case 2: {
        return (
          <>
            <span role="img" aria-label={unit.mode} title="Partially Boosting">
              🐌
            </span>
            {showName && "Partially Boosting"}
          </>
        );
      }
      case 3: {
        return (
          <>
            <span role="img" aria-label={unit.mode} title="Suspended">
              💤
            </span>
            {showName && "Suspended"}
          </>
        );
      }
      default: {
        return null;
      }
    }
  }
}

export default UnitMode;
