import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { FiSun, FiMoon } from "react-icons/fi";

class RealTimeOfDayProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      hour: 0,
      weekday: "",
      hoursLeft: 0,
      progress: 0,
      updatedAtFormat: props.updatedAt
        ? moment(props.updatedAt).format("MM/DD HH:mm")
        : "",
      updatedAtFromNow: "",
    };

    this.timerID = null;
  }

  componentDidMount() {
    this.parseTime();
    this.timerID = setInterval(() => {
      this.parseTime();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  parseTime = () => {
    const { timezone, updatedAt } = this.props;

    const currentTime = moment().tz(timezone);

    const mDate = moment(currentTime);
    const hour = mDate.hour();

    this.setState({
      date: mDate.format("YYYY-MM-DD"),
      time: mDate.format("HH:mm:ss"),
      hour,
      weekday: mDate.format("ddd"),
      hoursLeft: 24 - hour,
      progress: _.round((hour / 24) * 100, 0),
      updatedAtFromNow: updatedAt ? moment(updatedAt).fromNow() : "",
    });
  };

  render() {
    const {
      date,
      time,
      hour,
      weekday,
      hoursLeft,
      progress,
      updatedAtFromNow,
      updatedAtFormat,
    } = this.state;

    return (
      <>
        <div
          className="flex items-center"
          title={`${hoursLeft} hours until midnight`}
        >
          <div className="flex items-center">
            <DayIcon hour={hour}></DayIcon>
            <div className="mx-1">
              {date} {weekday}
            </div>
            <div className="font-mono font-bold">{time}</div>
          </div>

          <div className="w-16 pr-2">
            <div
              className="ml-1 -mr-2 bg-gray-400"
              style={{
                // position: "relative",
                overflow: "hidden",
                height: "4px",
              }}
            >
              <div
                style={{
                  float: "left",
                  marginLeft: `48%`,
                  height: "8px",
                  width: "1px",
                  backgroundColor: "indigo",
                }}
              ></div>
              <div
                className="bg-indigo-400"
                style={{ width: `${progress}%`, height: "8px" }}
              ></div>
            </div>
          </div>
          <div className="ml-1 text-gray-600">{progress}%</div>
        </div>
        {updatedAtFromNow !== "" && updatedAtFormat !== "" && (
          <div className="py-1 text-xs leading-none text-gray-700">
            Data updated {updatedAtFromNow} at {updatedAtFormat}
          </div>
        )}
      </>
    );
  }
}

const DayIcon = ({ hour }) => {
  if (hour <= 6 || hour > 18) {
    return (
      <div className="text-xs text-gray-600">
        <FiMoon></FiMoon>
      </div>
    );
  } else {
    return (
      <div className="text-xs text-gray-600">
        <FiSun></FiSun>
      </div>
    );
  }
};

export default RealTimeOfDayProgressBar;
