import React from "react";
import { FiCopy, FiLink } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";

class ClickToCopyWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCopied: false,
    };
  }

  render() {
    const { isCopied } = this.state;
    const {
      copyText,
      tooltipMessage = "Click to copy",
      buttonText = "",
      iconType = "COPY",
    } = this.props;
    const copyTextStr =
      typeof copyText !== "string" ? copyText.toString() : copyText;

    if (copyTextStr.length === 0) {
      // nothing to copy
    }

    return (
      <>
        <div className="flex items-center">
          <CopyToClipboard
            text={copyTextStr}
            onCopy={() => this.setState({ isCopied: true })}
          >
            <div
              className="flex cursor-pointer items-center gap-1 px-2 hover:text-blue-600"
              data-tip
              data-for={copyTextStr}
              onMouseLeave={() => this.setState({ isCopied: false })}
            >
              {buttonText}
              {iconType === "COPY" && <FiCopy></FiCopy>}
              {iconType === "LINK" && <FiLink></FiLink>}
            </div>
          </CopyToClipboard>
          <ReactTooltip id={copyTextStr} type="dark" effect="solid">
            {isCopied ? "Copied!" : tooltipMessage}
          </ReactTooltip>
        </div>
      </>
    );
  }
}

export default ClickToCopyWrapper;
