import React from "react";
import _ from "lodash";

import UprPriceSection from "../gam-upr/UprPriceSection";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";
import OpenLinkInNewWindowWrapper from "components/common/OpenLinkInNewWindowWrapper";
import UprTargetingPopover from "components/ops-mgmt/account/custom-upr/UprTargetingPopover";

const PRICE_TYPE = {
  0: "Floor",
  1: "Target CPM",
  2: "LGO", // let google optimize
  "-1": "Use Brand Setting",
};

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

const buttonClass =
  "px-2 py-1 bg-gray-100 text-xs rounded border border-gray-400 hover:bg-gray-200 text-gray-900 font-semibold";

function FirstLookRulesTable({
  items,
  units,
  virtualPlacements,
  networkId,
  networkInfo,
  currency,
}) {
  const networkCode = networkInfo.code;

  return (
    <table className="table w-full text-sm">
      <thead className="border-b bg-gray-200 text-xs text-blue-800">
        <tr>
          <th className="border py-1 px-2 text-center ">External ID</th>
          <th className="border py-1 px-2 text-left ">Name</th>
          <th className="border py-1 px-2 text-center ">Priority</th>
          <th className="border py-1 px-2 text-center ">Status</th>
          <th className="border py-1 px-2 text-center ">Type</th>
          <th className="border py-1 px-2 text-right ">Price Section</th>
          <th
            className="border py-1 px-2 text-left"
            style={{ paddingRight: "124px" }}
          >
            Targeting Section
          </th>
        </tr>
      </thead>
      <tbody className="bg-white  text-gray-900">
        {items.map((item, i) => {
          const gamLink = `https://admanager.google.com/${networkCode}#inventory/first_look/detail/first_look_id=${item.externalId}`;

          return (
            <tr key={i} className="border-b hover:bg-gray-100">
              <td className="border py-1 px-2 text-right">
                <div className="flex items-center justify-between">
                  <span className="font-mono">{item.externalId}</span>

                  <div className="flex items-center text-blue-500">
                    <OpenLinkInNewWindowWrapper
                      href={gamLink}
                      tooltipText="Open in GAM"
                    ></OpenLinkInNewWindowWrapper>
                    <ClickToCopyWrapper
                      copyText={gamLink}
                      tooltipMessage="Click to copy GAM link"
                    ></ClickToCopyWrapper>
                  </div>
                </div>
              </td>
              <td className="border py-1 px-2 text-left">{item.name}</td>
              <td className="border py-1 px-2 text-center">{item.priority}</td>
              <td className="border py-1 px-2 text-center">{item.status}</td>
              <td className="border py-1 px-2 text-center">{item.type}</td>
              <td className="border py-1 px-2 text-right">
                <UprPriceSection
                  item={item.priceSection}
                  currency={item.priceSection.currency}
                  networkId={networkId}
                ></UprPriceSection>
              </td>
              <td className="border py-1 px-2 text-left font-mono">
                <div>
                  {item.targetingSection && (
                    <UprTargetingPopover
                      networkId={networkId}
                      units={units}
                      virtualPlacements={virtualPlacements}
                      targetingSection={item.targetingSection}
                      triggerElement={
                        <div className="inline-block cursor-pointer  text-blue-600 hover:text-blue-700 hover:underline">
                          {Object.keys(item.targetingSection).join(", ")}
                        </div>
                      }
                    ></UprTargetingPopover>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default FirstLookRulesTable;
