import React from "react";
import _ from "lodash";
import EnabledIcon from "./EnabledIcon";
import { INVENTORY_REGISTRY } from "constants/InventoryRegistry";
import { FiAlertTriangle } from "react-icons/fi";

const buttonClass =
  "px-2 py-1 bg-gray-100 text-xs rounded border border-gray-400 hover:bg-gray-200 text-gray-900 font-semibold";

const RI = _.keyBy(INVENTORY_REGISTRY, "id");

class CSTIntegrationControlView extends React.PureComponent {
  render() {
    const {
      intConfig,
      handleOpenOverride,
      handleEditOverride,
      handleRemoveOverride,
      handleIsOverrideInvalid,
    } = this.props;
    const registeredInventories = _.keys(intConfig["registered_inventory"]);
    const isEnabled = intConfig["enable_optimization"];

    return (
      <div>
        <div className="mb-2">
          <span className="text-gray-700">Enable Optimization: </span>
          {isEnabled ? "true" : "false"}
        </div>

        {isEnabled && (
          <>
            <div className="mb-2">
              <span className="text-gray-700">
                Group Traffic Ratio Options:{" "}
              </span>

              <GroupTrafficRatioOptions
                value={intConfig["group_traffic_ratio_options"]}
              ></GroupTrafficRatioOptions>
            </div>
            <div className="border-b mb-2">
              <span className="text-gray-700">
                Traffic Ratio Option Index:{" "}
              </span>
              {_.isNil(intConfig["traffic_ratio_option_index"])
                ? "-"
                : intConfig["traffic_ratio_option_index"]}
            </div>

            {intConfig["ab_test_traffic_allocation"] &&
              intConfig["ab_test_traffic_allocation"].__is_enabled && (
                <div className="border-b mb-2">
                  <div className="text-lg font-semibold text-gray-700">
                    AB Test Traffic Allocation
                  </div>
                  <div>
                    <span className="text-gray-700">Optimization Ratio: </span>
                    {_.isNil(
                      intConfig["ab_test_traffic_allocation"][
                        "optimization_ratio"
                      ]
                    )
                      ? "-"
                      : intConfig["ab_test_traffic_allocation"][
                          "optimization_ratio"
                        ]}
                    %
                  </div>
                  <div>
                    <span className="text-gray-700">
                      Traffic Ratio for OPT Values:{" "}
                    </span>
                    {_.isNil(
                      intConfig["ab_test_traffic_allocation"][
                        "traffic_ratio_for_opt_values"
                      ]
                    )
                      ? "-"
                      : intConfig["ab_test_traffic_allocation"][
                          "traffic_ratio_for_opt_values"
                        ].join(", ")}
                  </div>
                  <div>
                    <span className="text-gray-700">
                      Traffic Ratio for Benchmark Values:{" "}
                    </span>
                    {_.isNil(
                      intConfig["ab_test_traffic_allocation"][
                        "traffic_ratio_for_bm_values"
                      ]
                    )
                      ? "-"
                      : intConfig["ab_test_traffic_allocation"][
                          "traffic_ratio_for_bm_values"
                        ].join(", ")}
                  </div>
                  <div>
                    <span className="text-gray-700">Tagging Frequency: </span>
                    {_.isNil(
                      intConfig["ab_test_traffic_allocation"][
                        "tagging_frequency"
                      ]
                    )
                      ? "-"
                      : _.capitalize(
                          intConfig["ab_test_traffic_allocation"][
                            "tagging_frequency"
                          ].replace("_", " ")
                        )}
                  </div>
                </div>
              )}

            <div className="mb-2">
              <span className="text-gray-700">
                Optimization Experimental Traffic Ratio:{" "}
              </span>
              {_.isNil(intConfig["opt_exp_traffic_ratio"])
                ? "-"
                : intConfig["opt_exp_traffic_ratio"]}
            </div>
            <div className="mb-2">
              <span className="text-gray-700">
                Publisher UPR Protection Mode:{" "}
              </span>
              {_.isNil(intConfig["upr_protection_mode"])
                ? "-"
                : intConfig["upr_protection_mode"]}
            </div>
            <div>
              <span className="text-gray-700">Registered Inventory: </span>
              <div>
                {registeredInventories.map((riKey) => {
                  const riConfig = intConfig["registered_inventory"][riKey];
                  const isEnabled = riConfig["enable_optimization"];

                  return (
                    <div key={riKey} className="border p-2">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-1 font-semibold">
                          {riKey}
                          <EnabledIcon isEnabled={isEnabled}></EnabledIcon>
                        </div>
                      </div>
                      {isEnabled && (
                        <div className="text-sm text-gray-900">
                          <div>
                            <span className="text-gray-700">
                              Time Granularity:
                            </span>{" "}
                            {riConfig["time_granularity"] || "-"}
                          </div>
                          <div>
                            <span className="text-gray-700">
                              Optimized Formats:
                            </span>{" "}
                            {riConfig["optimized_formats"] &&
                              riConfig["optimized_formats"].join(", ")}
                          </div>
                          {riConfig["browsers"] && (
                            <div>
                              <span className="text-gray-700">Browsers:</span>{" "}
                              {riConfig["browsers"].join(", ")}
                            </div>
                          )}
                          {riConfig["operating_systems"] && (
                            <div>
                              <span className="text-gray-700">OS: </span>
                              {riConfig["operating_systems"].join(", ")}
                            </div>
                          )}
                          <div>
                            <div className="mb-1 flex items-center justify-between text-gray-700">
                              <div>Overrides:</div>
                              <button
                                type="button"
                                className={buttonClass + " float-right"}
                                onClick={() =>
                                  handleOpenOverride({ ri: riKey, riConfig })
                                }
                              >
                                Add Override
                              </button>
                            </div>
                            <div>
                              {riConfig["overrides"]
                                ? _.sortBy(
                                    riConfig["overrides"],
                                    "registry_id"
                                  ).map((item) => {
                                    const isInvalid = handleIsOverrideInvalid({
                                      ri: riKey,
                                      riConfig,
                                      override: item,
                                    });

                                    return (
                                      <div
                                        key={item.registry_id}
                                        className="mb-1"
                                      >
                                        <div
                                          className={`flex items-center justify-between ${
                                            isInvalid
                                              ? "bg-red-200 text-red-800"
                                              : "bg-gray-200 text-gray-800"
                                          } px-2`}
                                        >
                                          <div>
                                            <div className="flex items-center gap-2 font-semibold">
                                              {isInvalid && (
                                                <FiAlertTriangle></FiAlertTriangle>
                                              )}
                                              {item.registry_id}:{" "}
                                              {RI[item.registry_id].label}
                                            </div>
                                            <div className="flex gap-4">
                                              {_.keys(item).map((key) => {
                                                if (key === "registry_id")
                                                  return "";
                                                return (
                                                  <span key={key}>
                                                    {key}: {item[key]}
                                                  </span>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div className="flex gap-4">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleEditOverride({
                                                  ri: riKey,
                                                  riConfig,
                                                  currentOverride: item,
                                                })
                                              }
                                              className="text-blue-600 hover:text-blue-800 hover:underline"
                                            >
                                              Edit
                                            </button>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleRemoveOverride({
                                                  ri: riKey,
                                                  registryId: item.registry_id,
                                                })
                                              }
                                              className={`${
                                                isInvalid
                                                  ? "text-red-600 hover:text-red-800"
                                                  : "text-blue-600 hover:text-blue-800"
                                              } hover:underline`}
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : "-"}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

function GroupTrafficRatioOptions({ value }) {
  if (!value) {
    return "-";
  }
  const option1 = value[0];
  const option2 = value[1];
  if (!option1 || !option2) {
    return "-";
  }
  return (
    <div className="text-sm text-gray-900">
      <div>
        Option 1:{" "}
        <span className="font-semibold">
          {option1[0]} | {option1.slice(1).join(", ")}
        </span>
      </div>
      <div>
        Option 2:{" "}
        <span className="font-semibold">
          {option2[0]} | {option2.slice(1).join(", ")}
        </span>
      </div>
    </div>
  );
}

export default CSTIntegrationControlView;
