import React from "react";

class UnitStatus extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { unit, showName } = this.props;

    switch (unit.status) {
      case 0:
      case "RUNNING": {
        return (
          <>
            <span role="img" aria-label={unit.status} title="Running">
              ▶️
            </span>{" "}
            {showName && "Running"}
          </>
        );
      }
      case 1:
      case "PAUSED": {
        return (
          <>
            <span role="img" aria-label={unit.status} title="Paused">
              ⏸
            </span>{" "}
            {showName && "Paused"}
          </>
        );
      }
      default: {
        return null;
      }
    }
  }
}

export default UnitStatus;
