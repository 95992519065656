import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import RealTimeOfDayProgressBar from "./RealTimeOfDayProgressBar";

class InfoWidget extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpenView: false,
      scrollPosition: 0,
      scrollIndex: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { itemRefs } = this.props;
    const { scrollPosition, scrollIndex } = this.state;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (Math.abs(scrollTop - scrollPosition) < 50) return;

    let index = scrollIndex;
    if (index >= itemRefs.length) {
      index = itemRefs.length - 1;
    } else {
      const adjust = 200;
      const pre =
        scrollIndex === 0
          ? 0
          : itemRefs
              .slice(0, scrollIndex)
              .reduce((acc, cur) => acc + cur.current.clientHeight, 0);
      const next = pre + itemRefs[scrollIndex].current.clientHeight;
      if (scrollTop > next - adjust && index < itemRefs.length - 1) index += 1;
      if (scrollTop < pre - adjust && index > 0) index -= 1;
    }

    this.setState({ scrollPosition: scrollTop, scrollIndex: index });
  };

  handleClick = () => {
    this.setState({ isOpenView: !this.state.isOpenView });
  };

  render() {
    const { itemList } = this.props;
    const { isOpenView, scrollIndex } = this.state;

    return (
      <>
        <div
          className="border shadow rounded fixed bottom-0 right-0 m-2 border-blue-400 bg-gray-200 px-2 py-1"
          style={{ zIndex: 2 }}
        >
          <div>
            <div className="mb-1 flex items-end justify-between gap-2">
              <div className="text-sm font-semibold">
                {itemList[scrollIndex] && itemList[scrollIndex].timezone}
              </div>
              <div
                className="border cursor-pointer rounded-full border-gray-400 bg-gray-200 p-1 text-xs text-gray-800 hover:bg-gray-300"
                onClick={this.handleClick}
              >
                {isOpenView ? (
                  <IoIosArrowDown></IoIosArrowDown>
                ) : (
                  <IoIosArrowUp></IoIosArrowUp>
                )}
              </div>
            </div>
            <div className="my-1 flex items-end text-xs text-gray-900">
              <div>
                {itemList[scrollIndex] && (
                  <RealTimeOfDayProgressBar
                    key={itemList[scrollIndex].mainId}
                    timezone={itemList[scrollIndex].timezone}
                    updatedAt={itemList[scrollIndex].updatedAt}
                  ></RealTimeOfDayProgressBar>
                )}
              </div>
            </div>
            {isOpenView && (
              <div className="my-1">
                <div className="border-t flex gap-2 border-gray-400 text-sm">
                  <div>
                    {itemList[scrollIndex] && itemList[scrollIndex].mainId}
                  </div>
                  <div>
                    {itemList[scrollIndex] && itemList[scrollIndex].mainTitle}
                  </div>
                </div>
                <div className="flex gap-2 text-xs leading-none text-gray-700">
                  <div>
                    {itemList[scrollIndex] && itemList[scrollIndex].subId}
                  </div>
                  <div>
                    {itemList[scrollIndex] && itemList[scrollIndex].subTitle}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default InfoWidget;
