import React from "react";
import _ from "lodash";

import { notify } from "react-notify-toast";

import { PublisherAPI } from "apis";
import PublishersTable from "./PublishersTable";
import LoadingUI from "../../../common/LoadingUI";
import CurrencySelector from "components/common/CurrencySelector";

class UpdatePublisherCurrencySetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      publisherId: null,
      tempPublisher: null,
      errMsg: "",

      pricingCurrency: null,
      reportingCurrency: null,
      recommendedCurrencies: [],
    };

    this.handleUpdate = this.handleUpdate.bind(this);
  }

  async componentDidMount() {
    try {
      const {
        publisherId,
        pricingCurrency,
        reportingCurrency,
        recommendedCurrencies,
      } = this.props.publisher;

      this.setState({
        publisherId,
        tempPublisher: this.props.publisher,

        pricingCurrency,
        reportingCurrency,
        recommendedCurrencies,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleCurrencyChanged = (currency, key) => {
    this.setState({
      [key]: currency,
    });
  };

  async handleUpdate() {
    try {
      const { publisherId, pricingCurrency, reportingCurrency } = this.state;
      const { handleUpdateData, handleClose } = this.props;
      if (!pricingCurrency && !reportingCurrency) {
        throw "Missing 'Pricing Currency', or 'Reporting Currency'!";
      }

      const params = {
        publisherId,
        pricingCurrency,
        reportingCurrency,
      };

      this.setState({
        isSaving: true,
      });

      const {
        result: {
          data: {
            update: { publisher },
          },
        },
      } = await PublisherAPI.updatePublisherCurrencySettings(params);

      const tempPublisher = _.cloneDeep(this.state.tempPublisher);
      tempPublisher.pricingCurrency = publisher.pricingCurrency;
      tempPublisher.reportingCurrency = publisher.reportingCurrency;

      this.setState({
        isSaving: false,
        errMsg: "",
        tempPublisher,
      });

      handleUpdateData(tempPublisher);

      notify.show("Currency settings updated!", "success");
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
      });
    }
  }

  isDisabled = () => {
    const { tempPublisher, pricingCurrency, reportingCurrency } = this.state;
    return (
      tempPublisher.pricingCurrency === pricingCurrency &&
      tempPublisher.reportingCurrency === reportingCurrency
    );
  };

  render() {
    const {
      tempPublisher,
      pricingCurrency,
      reportingCurrency,
      recommendedCurrencies,
      isLoading,
      isSaving,
      errMsg,
    } = this.state;

    const { handleClose } = this.props;

    return (
      <div>
        <div className="bg-white pb-4">
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            Update Publisher Trial Status
          </div>

          {tempPublisher && (
            <PublishersTable
              items={[tempPublisher]}
              hideAction={true}
            ></PublishersTable>
          )}
        </div>

        {isLoading && <LoadingUI></LoadingUI>}

        {tempPublisher && (
          <div className="bg-gray-200 p-4">
            <div>
              <div className="mb-4">
                <label className="font-semibold text-gray-800">
                  Pricing Currency:
                </label>
                <div className="relative z-10">
                  <CurrencySelector
                    selectedCurrency={pricingCurrency}
                    currencies={recommendedCurrencies}
                    handleCurrencyChanged={(data) =>
                      this.handleCurrencyChanged(data, "pricingCurrency")
                    }
                  ></CurrencySelector>
                </div>
              </div>

              <div>
                <label className="font-semibold text-gray-800">
                  Reporting Currency:
                </label>
                <div className="relative z-0">
                  <CurrencySelector
                    selectedCurrency={reportingCurrency}
                    currencies={recommendedCurrencies}
                    handleCurrencyChanged={(data) =>
                      this.handleCurrencyChanged(data, "reportingCurrency")
                    }
                  ></CurrencySelector>
                </div>
              </div>
            </div>

            <div className="mt-4 flex flex-row-reverse items-center">
              <div>
                <button
                  type="button"
                  disabled={this.isDisabled()}
                  className={`rounded px-4 py-2 font-semibold text-white ${
                    this.isDisabled()
                      ? "cursor-not-allowed bg-blue-300"
                      : "shadow bg-blue-500 hover:bg-blue-700"
                  }`}
                  onClick={() => this.handleUpdate()}
                >
                  {isSaving ? "Saving..." : "Update"}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  disabled={isSaving}
                  className={`px-4 py-2 text-blue-700 ${
                    isSaving ? "cursor-not-allowed" : ""
                  }`}
                  onClick={() => handleClose()}
                >
                  Back
                </button>
              </div>
              <div className="text-red-600">{errMsg}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UpdatePublisherCurrencySetting;
